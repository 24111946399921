import React, { Fragment } from "react"
import Layout from "./../components/layout"
import Article from "./../components/article"
import { graphql } from "gatsby"
import { ILocation } from "./../interface/location"

// bblpivotal.no/slett-din-bruker
const SlettDinBrukerPage: React.FC<{
    location: ILocation
    data: {
        slett_din_bruker: {
            edges: {
                node: {
                    frontmatter: {
                        pagename: string
                        heading: string
                        ingress: string
                        subheading: string
                        content: string
                        contact: string
                        contactEmail: string
                        contactTel: string
                        contactPicture: {
                            childImageSharp: {
                                gatsbyImageData: {
                                    quality: number
                                    layout: string
                                }
                            }
                        }
                    }
                }
            }[]
        }
    }
}> = ({ location, data }) => {
    const edges = data.slett_din_bruker.edges[0].node.frontmatter

    const contentIngress = {
        ingress: edges.ingress,
        pagename: edges.pagename,
    }

    return (
        <Layout location={location} hero={true} ingress={contentIngress}>
            <Article
                heading={edges?.heading}
                subtitle={edges?.subheading}
                content={edges?.content}
                contact={edges?.contact}
                contactEmail={edges?.contactEmail}
                contactTel={edges?.contactTel}
                contactPicture={
                    edges?.contactPicture?.childImageSharp?.gatsbyImageData
                }
                slimImage={true}
            />
        </Layout>
    )
}

export default SlettDinBrukerPage

export const data = graphql`
    query SlettDinBruker {
        slett_din_bruker: allMarkdownRemark(
            filter: {
                fileAbsolutePath: {
                    regex: "/CMSContent/Subpages/slett-din-bruker/"
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        pagename
                        heading
                        ingress
                        subheading
                        content
                        contact
                        contactEmail
                        contactTel
                        contactPicture {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    layout: FULL_WIDTH
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
